import React from 'react'
import Layout from '../components/layout/index.amp'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {metadata} from '../../config'
import loadable from '@loadable/component'
import {formatDate} from '../utils/articleUtil'
import {orderBy as _orderBy} from 'lodash'
import Helmet from 'react-helmet'
import Faq from '../components/faq/index.amp'
import ShareAmp from '../components/share/sharing.amp'
import '../css/components/_article.scss'
import CategoryTitleBordered from '../components/helpers/categoryTitleBordered.amp'
import EmbedYoutubeAmp from '../components/youtube-video/EmbedYoutubeAmp';
import ReactHtmlParser, {convertNodeToElement} from 'react-html-parser';
import SubscriptionBanner from '../components/subscribe/Banner.amp';

const AuthorDetails = loadable(() => import("./authorDetails.amp"));
const WhatNextAMP = loadable(() => import("../components/whatNext/index.amp"));
class Article extends React.Component {

    constructor(props) {
        super(props);
        this.data = props.pageContext.article;
        this.url = `${metadata.url}/${props.pageContext.url}`;
        this.categories = _orderBy(this.data.category, ['position'], ['asc']);
        this.subCategory = this.categories.find(item => item.treeParent !== null);
        this.longAdCount = 0;
        this.lastIndexOfPTag = 0;

        this.getRecommendedPosts();
    }

  getRecommendedPosts() {
    this.recommendedPosts = [];
    this.popularPosts = [];

    const slugs = [];
    let posts = [];
    const relatedArticlesObject = this.props.pageContext.relatedArticles;
    posts = posts
      .concat(relatedArticlesObject.tags10 || [])
      .concat(relatedArticlesObject.tags9 || [])
      .concat(relatedArticlesObject.tags8 || [])
      .concat(relatedArticlesObject.tags7 || [])
      .concat(relatedArticlesObject.tags6 || [])
      .concat(relatedArticlesObject.tags5 || [])
      .concat(relatedArticlesObject.tags4 || [])
      .concat(relatedArticlesObject.tags3 || [])
      .concat(relatedArticlesObject.tags2 || [])
      .concat(relatedArticlesObject.tags1 || [])
      .concat(relatedArticlesObject.sameCategory || [])
      .concat(relatedArticlesObject.random || []);

    for (let i = 0; i < posts.length; i++) {
      const post = posts[i];
      if (!slugs.includes(post.slug)) {
        this.recommendedPosts.push(post);
        slugs.push(post.slug);
      }
    }
  }

  getContent() {
    let content = this.data.content.replace(/(?:\r\n|\r|\n)/g, '');
    content = content.replace(/ aria-level="1"/g, '');
    content = content.replace(/ style="font-weight: 400;"/g, '');
    content = content.replace(/ padding-left:40px/g, '');
    return content;
  }

  transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      return <div style={{textAlign: "center"}}>
        {convertNodeToElement(node, index, null)}
      </div>;
    }
    if (node.type === 'tag' && node.name === 'table') {
      node.attribs.style = 'margin-left: auto; margin-right: auto;' + (node.attribs.style ? node.attribs.style : '');
      return convertNodeToElement(node, index, null)
    }
    if(node.type === 'tag' && node.name === 'p' && (!node.parent || node.parent.name === 'div')) {
      ++this.lastIndexOfPTag
      let customElements = [];
      this.lastIndexOfPTag === 3 && customElements.push(<div className={"mb-4"}>
        <SubscriptionBanner />
      </div>)
      if (this.lastIndexOfPTag === 4) {
        customElements.push(<amp-ad width="336"
                                    height="280"
                                    type="doubleclick"
                                    data-slot="/22218985137/agetimes/at_incontent1_mobile"
                                    data-multi-size="300x250"
                                    data-multi-size-validation="false"/>);
      }
      if (this.lastIndexOfPTag === 12) {
        customElements.push(<amp-ad width="336"
                                    height="280"
                                    type="doubleclick"
                                    data-slot="/22218985137/agetimes/at_incontent2_mobile"
                                    data-multi-size="300x250"
                                    data-multi-size-validation="false"/>);
      }
      if (this.lastIndexOfPTag >= 16 && this.lastIndexOfPTag%8 === 0) {
        ++this.longAdCount;
        customElements.push(<amp-ad width="336"
                                    height="280"
                                    type="doubleclick"
                                    data-slot="/22218985137/agetimes/at_incontent2_mobile"
                                    data-multi-size="300x250"
                                    data-multi-size-validation="false"/>);
      }
      if (this.lastIndexOfPTag === 8 || this.lastIndexOfPTag === 13 || this.lastIndexOfPTag === 16) {
        customElements.push(<WhatNextAMP recommendedPosts={this.recommendedPosts.splice(0, 2)}/>);
      }

      if (customElements.length) {
        return <>
          {convertNodeToElement(node, index, null)}
          {customElements.map(element => element)}
        </>;
      }
    }
  }

    render() {
        return <Layout>
            <Helmet>
                <meta name="keywords" content={this.data.tags}/>
            </Helmet>
            <Basic seo={this.data.seo}/>
            <Twitter seo={this.data.seo} url={this.url}/>
            <Facebook seo={this.data.seo} url={this.url}/>
            <main className="wrapper" data-datocms-noindex>
                <article className="w-full">
                    <CategoryTitleBordered className={"uppercase"}
                                           link={`/${this.subCategory.treeParent.slug}/${this.subCategory.slug}`}
                                           title={this.subCategory.title}/>
                    <header className="font-arial">
                        {this.data.ptNewsVideo && <div className={"news-video mx-2"}>
                            <EmbedYoutubeAmp video={this.data.ptNewsVideo}/>
                        </div>}
                        <h1 className="text-2xl lg:text-5xl font-montserrat font-semibold mt-4 mb-0 leading-tight">{this.data.heading}</h1>
                        <div className={"flex items-center text-sm text-black text-opacity-50"}>
                            <div>
                                By <a href={`/authors/${this.data.author.slug}`} className={"text-denim article-author-name"}>{this.data.author.name}</a>
                            </div>
                            {/*<time className={"ml-2"}>{formatDate(this.data.publishedAt, 'mmmm dd, yyyy')}</time>*/}
                            <span>&nbsp;·&nbsp;{this.props.pageContext.readingTime} min read</span>
                        </div>
                        <p className="font-semibold text-base font-arial leading-snug mb-0 mt-4">{this.data.excerpt}</p>
                        <hr className={`border-t-default my-6 mx-10 header-separator`}/>
                        {this.data.json && this.data.json.faq &&
                        <Faq data={this.data.json.faq}
                             title={this.data.json.faqTitle ? this.data.json.faqTitle : `${this.data.heading}: FAQs`}></Faq>}
                    </header>
                  <main>
                    <div id="article-content">
                      {ReactHtmlParser(this.getContent(), {transform: this.transform})}
                    </div>
                  </main>
                    <ShareAmp/>
                  {(this.lastIndexOfPTag < 8 || this.lastIndexOfPTag > 18)  && <WhatNextAMP recommendedPosts={this.recommendedPosts.splice(0, 2)}/>}
                    <AuthorDetails author={this.data.author}
                                   deferImage={true}
                                   className={"w-4/5 mx-auto"}
                                   nameClass={"text-18 mr-2 text-black text-opacity-75"}
                                   bioClass={"text-black text-opacity-75 mt-2 mb-4 leading-relaxed font-light"}/>
                    {/*<amp-facebook-comments width="486" height="657" layout="responsive" data-href={this.url}/>*/}
                </article>
            </main>
        </Layout>
    }
}
export default Article
